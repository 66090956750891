import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import { logPageView } from '~/gtag';

export default function RouteWrapper({component: Component, ...rest}) {
  const Default = (
    <Route 
      {...rest} 
      render={(props) => {
        logPageView(); // Adicione a chamada para logPageView
        return <Component {...props} />;
      }} 
    />
  );

  return Default;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
