import React, {Fragment} from 'react'
import moment from 'moment';
import 'moment/locale/pt-br';

import AtendimentoManut from '~/views/private/Cadastros/Atendimentos/Manutencao';
import ModalOferta from '~/components/ModalOfertaAtiva';
import ModalGenerico from '~/components/ModalGenerico';
import ModalOfertasAtivasDisponiveis from '~/components/ModalOfertasAtivasDisponiveis';
import useSWRMutation from 'swr/mutation';
import url from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import useLocalStorage from '~/hooks/localstorage/useLocalStorage';
import useOfertaAtivaCorretorStore, { ModalType } from './useOfertaAtivaCorretorStore';

moment.locale('pt-BR');

/* Constantes */

const TipoMidiaOfertaAtiva = 5; // OFERTA ATIVA
const SituacaoAtivo = 1; // ATIVO
const OfertaAtivaStatusEmNegociacao = 2; // EM NEGOCIAÇÃO

/* Funções */

const payloadOfertaAtiva = ({ pessoaId, nomeCompleto, telefone1, telefone2, telefone3 }) => ({
  corretorId: pessoaId,
  tipoMidia: TipoMidiaOfertaAtiva,
  situacao: SituacaoAtivo,
  nomeCliente: nomeCompleto,
  foneCliente: [telefone1, telefone2, telefone3].find(telefone => telefone && telefone.trim() !== ''),
  descricaoUltimoAtendimento: 'Atendimento gerado via oferta ativa',
  dataUltimoAtendimento: moment(new Date(), 'YYYY-MM-DD'),
  status: OfertaAtivaStatusEmNegociacao,
});

const payloadEditarOfertaAtiva = (cliente, status) => ({
  filaAtendimentoId: cliente.id,
  status: status,
});

export default function ModalContainer({
  onReload,
}) {
  const [pessoaId] = useLocalStorage('UsuarioId');
  const {
    ofertaAtivaSelecionada,
    isModal,
    verOfertaAtiva,
    closeModal,
    listaOfertasAtivas,
    startAtendimento,
    atendimentoId,
  } = useOfertaAtivaCorretorStore();

  const { trigger: mutateEditarOfertaAtiva } = useSWRMutation(url.OFERTA_ATIVA.EDITAR(), (url, { arg }) => {
    return api.put(url, arg);
  });

  const { trigger: mutateCadastrarAtendimentoPelaOfertaAtiva } = useSWRMutation(url.ATENDIMENTO.CADASTRO_BY_OFERTA_ATIVA(), (url, { arg }) => {
    return api.post(url, arg);
  });

  return (
    <Fragment>
      {isModal(ModalType.ListaOfertasDisponiveis) && (
        <ModalOfertasAtivasDisponiveis
          open={true}
          onOfertaAtivaSelecionada={(ofertaAtiva) => {
            verOfertaAtiva(ofertaAtiva);
          }}
          onClose={() => {
            closeModal();
            onReload();
          }}
        />
      )}

      {isModal(ModalType.VerOfertasAtivas) && (
        <ModalOferta
          open={true}
          closeModal={() => {
            closeModal();
            onReload();
          }}
          ofertaAtivaSelecionada={ofertaAtivaSelecionada}
          response={(cliente, status) => {
            if (status === OfertaAtivaStatusEmNegociacao) {
              return mutateCadastrarAtendimentoPelaOfertaAtiva(payloadOfertaAtiva({ pessoaId, ...cliente }), {
                onSuccess: (data) => {
                  mutateEditarOfertaAtiva(payloadEditarOfertaAtiva(cliente, OfertaAtivaStatusEmNegociacao), {
                    onSuccess: () => {
                      startAtendimento(data?.data?.data?.id);
                      Notify('success', '', 'Ação realizada com sucesso!');
                    },
                    onError: () => {
                      onReload();
                      closeModal();
                      Notify(
                        'error',
                        '',
                        'Ooops, favor realizar uma nova tentativa mais tarde!',
                      );
                    }
                  })
                },
                onError: () => {
                  onReload();
                  closeModal();
                  Notify(
                    'error',
                    '',
                    'Ooops, favor realizar uma nova tentativa mais tarde!',
                  );
                }
              });
            }

            return mutateEditarOfertaAtiva(payloadEditarOfertaAtiva(cliente, status), {
              onSuccess: () => {
                onReload();
                closeModal();
                Notify('success', '', 'Ação realizada com sucesso!');
              },
              onError: () => {
                onReload();
                closeModal();
                Notify(
                  'error',
                  '',
                  'Ooops, favor realizar uma nova tentativa mais tarde!',
                );
              },
            })
          }}
          onTrocaLista={() => {
            listaOfertasAtivas();
          }}
          onListaVazia={() => {
            closeModal();
            onReload();
            Notify('warning', '', 'Não há ofertas ativas disponíveis!');
          }}
        />
      )}

      {isModal(ModalType.RealizarAtendimento) && (
        <ModalGenerico
          open={true}
          close={() => {
            closeModal();
            onReload();
          }}
          size="large"
        >
          <AtendimentoManut
            match={{ params: { modo: 'editar', tab: 2 } }}
            location={{ state: { id: atendimentoId } }}
          />
        </ModalGenerico>
      )}
    </Fragment>
  )
}
